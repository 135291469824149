<template>
  <section>
    <aAlert
      class="f12 mb-20"
      type="warning"
      message="Confira todos os dados dos beneficiários antes de realizar o envio em massa."
      banner
    />

    <div class="livelo-bulk-table">
      <aRow class="head f12" :gutter="10">
        <aCol :span="2"> ID </aCol>
        <aCol :span="6"> Nome do beneficiário </aCol>
        <aCol :span="4"> CPF </aCol>
        <aCol :span="4"> Regra Livelo </aCol>
        <aCol :span="4"> Pontos a enviar </aCol>
        <aCol :span="4"> Status do envio </aCol>
      </aRow>

      <aRow
        v-for="(contract, index) in liveloProductsArr"
        :gutter="10"
        :key="index"
        class="f12 row"
        :class="`contract-${contract.id}`"
      >
        <aCol :span="2"> {{ contract.id }} </aCol>
        <aCol class="upper" :span="6">
          <div class="dotted-phrase">
            {{ contract.customer.first_name }} {{ contract.customer.last_name }}
          </div>
        </aCol>
        <aCol :span="4">
          {{ contract.meta.livelo_customer_id }}
        </aCol>
        <aCol :span="4">
          R$ 1 = {{ contract.meta.livelo_points_rule }} pts
        </aCol>
        <aCol :span="4">
          {{ contract.meta.livelo_amount_to_receive }}
        </aCol>
        <aCol
          class="status"
          :span="4"
          :id="`contract-${contract.id}-livelo-status`"
        >
          <span
            :class="
              contract.meta.livelo_points_is_sent == 'Finalizado'
                ? 'cgreen'
                : ''
            "
          >
            {{ contract.meta.livelo_points_is_sent }}
          </span>
        </aCol>
      </aRow>
    </div>

    <aRow v-if="!loadingLiveloBulkRequest" class="mt-10">
      <aCol class="a-center">
        <a-divider />
        <a-popconfirm
          title="Tem certeza que deseja enviar pontos em massa?"
          ok-text="Sim"
          placement="top"
          cancel-text="Não"
          @confirm="getLiveloToken"
        >
          <a-button class="ml-20" type="primary">
            Enviar pontos Livelo em massa
          </a-button>
        </a-popconfirm>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import axios from "axios";
import qs from "qs";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "LiveloBulkEditModal",
  props: {
    liveloProductsArr: Array,
  },
  mixins: [formatThings],
  data() {
    return {
      loadingLiveloBulkRequest: false,
      theLiveloToken: "",
    };
  },
  mounted() {},
  methods: {
    getLiveloToken() {
      // https://apis-uat.pontoslivelo.com.br/api/oauth2/v1/token
      axios
        .post(
          "https://apis.pontoslivelo.com.br/api/oauth2/v1/token",
          qs.stringify({
            grant_type: "client_credentials",
            scope: "loyalty.customer.accrual.write",
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Basic VkpSSTpZZ3JmWEhxdEM1S0pLZTdEQ21XNA==",
            },
          }
        )
        .then(({ data }) => {
          this.theLiveloToken = data.access_token;
          this.bulkLiveloPointsSend(data.access_token);
        })
        .catch(({ response }) => {
          this.$message.warning(response.data.errorMessage);
        });
    },
    async bulkLiveloPointsSend() {
      this.loadingLiveloBulkRequest = true;
      for (const contract of this.liveloProductsArr) {
        await this.asyncOperation(contract);
      }

      await this.waitLoadingBulkRequest();
    },
    async asyncOperation(contract) {
      const baseUrl = "https://apis.pontoslivelo.com.br";
      const liveloProductionAPI = `${baseUrl}/api/loyalty/v1/customers/${contract.meta.livelo_customer_id}/accruals`;

      let liveloRequest = {
        date: contract.meta.livelo_transaction_date,
        partnerCode: "VJR",
        pointType: "COA_VJR",
        siteCode: "VJR_LOC",
        paymentProduct: "VJR_DEFAULT",
        transactionId: `TSS-${new Date().getFullYear()}${this.monthWithZero(
          new Date().getMonth() + 1
        )}${contract.id}`,
        amount: parseInt(contract.meta.livelo_amount),
        contract_id: contract.id,
      };

      if (contract.meta.livelo_haya_product_id)
        liveloRequest.productId = contract.meta.livelo_haya_product_id;

      await axios
        .post(liveloProductionAPI, liveloRequest, {
          headers: {
            Authorization: `Bearer ${this.theLiveloToken}`,
          },
        })
        .then(({ data }) => {
          document.getElementById(
            `contract-${contract.id}-livelo-status`
          ).innerHTML = '<font class="cgreen">Sucesso!</font>';

          this.$http.post("/log/create", {
            user_id: 1,
            module_id: 1,
            module: "user",
            action: "save-livelo-transaction",
            description: `Dev: ${JSON.stringify(data)}`,
          });

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: liveloRequest.contract_id,
            module: "contract",
            action: "create-livelo-transaction",
            description: `${this.$store.state.userData.first_name} ${
              this.$store.state.userData.last_name
            } criou uma transação Livelo (${data.transactionId}) ${
              liveloRequest.productId
                ? "para o produto ID (" + liveloRequest.productId + ")"
                : ""
            }  no contrato ${liveloRequest.contract_id}.`,
          });

          let updtContract = {};
          updtContract.id = liveloRequest.contract_id;

          updtContract.livelo_points_is_sent = "Finalizado";
          updtContract.livelo_transaction_id = data.transactionId;

          updtContract.livelo_haya_transaction_id = liveloRequest.transactionId;
          updtContract.livelo_haya_product_id = liveloRequest.productId
            ? liveloRequest.productId
            : "";
          updtContract.livelo_transaction_user_id =
            this.$store.state.userData.id;

          let theTransactionHour = new Date();
          theTransactionHour.setHours(theTransactionHour.getHours() - 3);

          updtContract.livelo_transaction_generation_date = theTransactionHour;

          updtContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/contract/update-multi-meta", updtContract)
            .then(() => {
              this.$message.success(
                `Dados Livelo atualizados no contrato ${updtContract.id}!`
              );
            });
        })
        .catch(() => {
          this.$message.error(
            `Erro ao enviar pontos Livelo do contrato ID ${contract.id}.`
          );
          document.getElementById(
            `contract-${contract.id}-livelo-status`
          ).innerHTML = '<font class="red">Erro ao enviar</font>';
        });
    },
    async waitLoadingBulkRequest() {
      this.loadingLiveloBulkRequest = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.livelo-bulk-table
  .head
    font-weight: 600
    padding: 6px 0
    background: #fafafa
    border-top: 1px solid #eee !important
    color: #666
  .row
    padding: 6px 0
    border-top: 1px solid #eee
</style>
